<template>
  <b-container>
    <div
      v-if="gesAuditStatus === 'pending'"
      class="d-flex justify-content-center mb-3"
    >
      <b-spinner
        variant="primary"
        label="Loading..."
      />
    </div>
    <b-alert
      v-if="gesAuditStatus === 'unavailable'"
      class="mb-3"
      show
      variant="warning"
    >
      <div class="alert-body">
        Aucune donnée disponible sur cette période
      </div>
    </b-alert>

    <template v-if="gesAuditStatus === 'success'">
      <b-row
        align-v="center"
        align-h="between"
      >
        <b-col
          cols="8"
          class="d-flex align-items-center mb-1"
        >
          <h2 class="m-0">
            Tableau de synthèse Gaz à effet de serre
          </h2>
          <b-dropdown
            v-if="gesCultures.length > 1"
            class="custom-dropdown ml-1"
            variant="outline-primary"
            :text="localize(currentGesCulture)"
            :disabled="gesAuditStatus === 'pending'"
          >
            <b-dropdown-item
              v-for="(culture, index) in gesCultures"
              :key="index"
              @click="$store.commit('verticalMenu/SET_GES_CULTURE', culture)"
            >
              {{ localize(culture) }}
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="8">
          <b-card class="Card">
            <b-row>
              <b-col
                cols="12"
                class="pb-2"
              >
                Total des émissions pour la culture de {{ localize(currentGesCulture) }}
              </b-col>
              <b-col
                cols="6"
                class="pb-2"
              >
                <GesGauge
                  :indicators="filteredIndicators"
                />
              </b-col>
              <b-col cols="6">
                <b-row>
                  <b-col cols="12">
                    <GesFilter
                      v-model="indicatorFilter"
                      :indicators="indicators"
                    />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col cols="4">
          <GesHelper v-if="isHelpShown" />
        </b-col>
      </b-row>
    </template>
  </b-container>
</template>

<script>
import {
  BContainer, BRow, BCol, BCard, BDropdown, BDropdownItem, BAlert, BSpinner
} from "bootstrap-vue";
import { mapState } from "vuex";
import GesFilter from "@/components/ges/GesFilter.vue";
import GesGauge from "@/components/ges/GesGauge.vue";
import GesHelper from "@/components/ges/GesHelper.vue";
import localize from "@/utils/localize";

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BCard,
    BDropdown,
    BDropdownItem,
    GesFilter,
    GesGauge,
    GesHelper,
    BAlert,
    BSpinner
  },
  data() {
    return {
      indicatorFilter: [],
      isHelpShown: false

    };
  },
  computed: {
    ...mapState("cooperative", ["gesAudit"]),
    ...mapState("cooperative", ["gesAuditStatus"]),
    ...mapState("verticalMenu", ["currentGesCulture", "gesCultures"]),
    indicators() {
      return this.gesAudit.data;
    },
    filteredIndicators() {
      if (this.indicatorFilter) {
        const filteredIndicators = [];
        this.indicators.forEach(indicator => {
          if (this.indicatorFilter.includes(indicator.handle)) {
            filteredIndicators.push(indicator);
          }
        });

        return filteredIndicators;
      }
      return null;
    }
  },
  methods: {
    toggleHelp() {
      this.isHelpShown = !this.isHelpShown;
    },
    localize
  }
};
</script>

<style lang="scss" scoped>
.Card {
  overflow: hidden;
}
</style>
